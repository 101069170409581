/* model out */
.model-out {
  width: 100%;
  padding: 10px;
  background-color: #7bba00;
  text-align: center;
  display: block;
  text-decoration: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.3px;
  transition: 0.3s;
}

/* platform */
.model-signup {
  padding: 20px;
  position: relative;
  display: flex;
}

.model-signup-background {
  padding: 20px;
  border-radius: 5px;
  display: flex;
  width: 100%;
}

.model-signup-container {
  width: 100%;
  font-size: 16px;
  text-align: center;
  line-height: 24px;
  -webkit-margin-after: auto;
  margin-block-end: auto;
  -webkit-margin-before: auto;
  margin-block-start: auto;
}

.model-signup-container img {
  width: 100%;
  max-width: 300px;
}

.model-signup-container a {
  background-color: #f20744;
  padding: 20px 0;
  display: block;
  border-radius: 30px;
  color: #fff;
  text-transform: uppercase;
}

.model-stats {
  padding: 20px;
}

.model-stats-background {
  margin-top: 14px;
  margin-bottom: 20px;
  background: #efefef;
  padding: 20px;
  /* color: #000; */
  border-radius: 5px;
  font-size: 14px;
  list-style-type: none;
}

.model-stats-background li {
  color: gray;
  display: flex;
  padding: 10px 0;
  transition: 0.3s;
  text-transform: capitalize;
  text-align: -webkit-match-parent;
}

.model-stats-background li span:first-child {
  min-width: 121px;
  display: block;
  color: #000;
  text-align: right;
  padding-right: 10px;
}

/* ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
} */

/* tag */
.model-tag {
  display: flex;
  flex-wrap: wrap;
}

.tag-danger {
  border: 1px solid #777;
  color: #777;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, segoe ui,
    helvetica neue, Arial, sans-serif, apple color emoji, segoe ui emoji,
    segoe ui symbol;
  margin-right: 5px;

  white-space: nowrap;
  border-radius: 10px;
  background-color: transparent;
  font-size: 11px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 0 8px;
  transition: 0.3s;

  margin-top: 1px;
  margin-bottom: 1px;

  text-decoration: underline;
  box-sizing: border-box;
}

.tag-danger:hover {
  border-color: transparent !important;
  background: #ff253a;
  /* Old browsers */
  background: -moz-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(-45deg, #ff253a 0%, #ff8453 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, #ff253a 0%, #ff8453 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff253a', endColorstr='#ff8453',GradientType=1 );
  /* IE6-9 fallback on horizontal gradient */
}
